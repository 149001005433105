import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded' // TODO remove

document.addEventListener('DOMContentLoaded', () => {
    initMasonry()
    initGalleries()
})

function initMasonry() {
    const elem = document.querySelector('.grid')
    if (!elem) return

    imagesLoaded(elem, initMasonryAfterImagesLoaded)
}

function initMasonryAfterImagesLoaded() {
    const elem = document.querySelector('.grid')

    const msnry = new Masonry( elem, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        gutter: 15
    })

    window.dispatchEvent(new Event('resize'))
}

function initGalleries() {
    const options = {};
    const slides = [];

    const pswp = document.querySelector('.pswp');
    if (!pswp) return;

    const links = document.querySelectorAll('.gallery .grid-item a');
    links.forEach(function(link, index) {
        const {width, height } = link.dataset;
        const { href } = link;

        const img = link.querySelector('img');
        const { alt } = img;

        const slide = {
            src: href,
            w: width,
            h: height,
            title: alt,
        }

        slides.push(slide);

        link.addEventListener('click', function(event) {
            event.preventDefault();
            showGallery(pswp, slides, index);
        })
    });

    // check for hash url params and if present, show gallery
    const hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        showGallery(pswp, slides, hashData.pid - 1, options)
    }
}

function showGallery(pswp, slides, index, options) {
    const myOptions = {
        index: index
    };
    const allOptions = Object.assign({}, options, myOptions);
    const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, slides, allOptions);
    // const gallery = new PhotoSwipe(pswp, null, slides, allOptions);
    gallery.init();
}

function photoswipeParseHash() {
    var hash = window.location.hash.substring(1),
    params = {};

    if(hash.length < 5) {
        return params;
    }

    var vars = hash.split('&');
    for (var i = 0; i < vars.length; i++) {
        if(!vars[i]) {
            continue;
        }
        var pair = vars[i].split('=');
        if(pair.length < 2) {
            continue;
        }
        params[pair[0]] = pair[1];
    }

    if(params.gid) {
        params.gid = parseInt(params.gid, 10);
    }

    return params;
};
