import Flickity from 'flickity'

document.addEventListener('DOMContentLoaded', () => {
    initHeaderSlider()
})

function initHeaderSlider() {
    const headerSliderElement = document.querySelector('.header-slider');
    if (!headerSliderElement) { return };

    const flkty = new Flickity( headerSliderElement, {
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        autoPlay: 5000,
    })
}
